import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { responsiveFont } from 'styles/utils';

import { variables, breakpoints } from 'styles/variables';

interface LinkProps {
  name: string;
  href: string;
  icon?: ReactNode;
}

const base = css`
  font-size: 18px;
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  display: inline-block;
  background: #000000;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  color: #ffffff;
  border-radius: 5px;

  transition: 300ms opacity ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`;

const LinkElement = styled.a`
  ${base};
`;

const Icon = styled.div`
  svg {
    position: relative;

    path {
      fill: #ffffff;
    }
    margin-left: 4px;
    top: 3px;
  }
`;

const Lnk = styled(AniLink)`
  ${base};
`;

export const BigButton = ({ name, href, className }: LinkProps) => {
  const isLink = typeof href !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(href || '');

  if (isExternal) {
    return (
      <LinkElement href={href} target="_blank" rel={'noopener noreferrer'} className={className}>
        {name}
      </LinkElement>
    );
  }

  return (
    <>
      <Lnk paintDrip to={href} hex="#000" className={className}>
        {name}
      </Lnk>
    </>
  );
};
