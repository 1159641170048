import React, { ReactNode, useState, useEffect } from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';

import Instagram from 'assets/svg/instagram.svg';
import Linkedin from 'assets/svg/linkedin.svg';

import { MagicLetter } from 'components/magic-letter/MagicLetter';

import Lottie from 'react-lottie-player';
import Elephant from 'assets/svg/elephant.json';

interface TeamProps {
  children: ReactNode;
}

const TeamRoot = styled.div`
  background-color: #eae7df;
`;

const Wrapper = styled.div`
  padding: 60px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 120px 0;
  }
`;

const Paragraph = styled.div`
  ${responsiveFont(24, 36)};

  font-family: ${variables.font.familyHeading};
  font-weight: 300;
`;

const SmallParagraph = styled.div`
  ${responsiveFont(18, 24)};

  font-family: ${variables.font.familyHeading};
  font-weight: 300;
  padding-bottom: 24px;
`;

const ParagraphEnormous = styled.div`
  ${responsiveFont(85, 400)};
  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  line-height: 100px;
  margin-left: -20px;
  min-width: 1500px;
  @media (min-width: ${breakpoints.md}) {
    line-height: 250px;
    margin-left: -45px;
  }
  @media (min-width: ${breakpoints.lg}) {
    line-height: 350px;
    margin-left: -60px;
  }
`;

const TeamMember = styled.p`
  ${responsiveFont(18, 24)};

  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 10px;
`;
const ClaimWrapper = styled.div`
  padding-top: 100px;
`;

const TeamListWrapper = styled.div`
  padding-top: 100px;
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 200px;
  }
`;

const TeamPhotoWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 48px;
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 24px;
    padding-bottom: 65px;
  }
`;

const TeamTitleWrapper = styled.div`
  padding-top: 100px;
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 160px;
  }
`;

const Title = styled.span`
  font-weight: 500;
`;

const MemberLink = styled.a`
  transition: 200ms opacity ease-in-out;
  padding: 10px;
  padding-left: 0px;
  line-height: 40px;
  display: inline-block;

  &:hover {
    opacity: 0.5;
  }
`;

const MemberBlock = styled.div`
  display: block;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

const AnimationWrapper = styled.div`
  position: fixed;
  bottom: -20px;
  right: 50px;
  z-index: 10000;
  max-width: 300px;
  max-height: 300px;
  pointer-events: none;
  display: none;
  @media (min-width: ${breakpoints.md}) {
    right: 100px;
  }
`;

const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 500;
  padding-bottom: 50px;
`;

export const Team = ({ data, children }) => {
  const [play, setPlay] = useState(false);

  useEffect(() => {
    var elephantTrigger = document.getElementById('elephantTrigger');
    var animationWrapper = document.getElementById('animationWrapper');
    var isLeaving = false;
    const observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          isLeaving = true;
          animationWrapper.style.display = 'block';
          setPlay(true);
        } else if (isLeaving) {
          // maybe someday set timer to remove it
        }
      });
    });
    observer.observe(elephantTrigger);
  });
  return (
    <TeamRoot>
      <AnimationWrapper id="animationWrapper">
        <Lottie
          loop={false}
          play={play}
          animationData={Elephant}
          style={{ width: '100%', height: '100%' }}
        />
      </AnimationWrapper>
      <Container>
        <Wrapper>
          <Row>
            <Column md={12} lg={12} sm={12}>
              <ParagraphEnormous>
                <MagicLetter>J</MagicLetter>
                <MagicLetter>u</MagicLetter>
                <MagicLetter>m</MagicLetter>
                <MagicLetter>p</MagicLetter>
                <div style={{ clear: 'both' }} />
                <MagicLetter>h</MagicLetter>
                <MagicLetter>i</MagicLetter>
                <MagicLetter>g</MagicLetter>
                <MagicLetter>h</MagicLetter>
                <MagicLetter>e</MagicLetter>
                <MagicLetter>r</MagicLetter>
                <div style={{ clear: 'both' }} />
                <MagicLetter>H</MagicLetter>
                <MagicLetter>a</MagicLetter>
                <MagicLetter>v</MagicLetter>
                <MagicLetter>e</MagicLetter>
                <div style={{ clear: 'both' }} />
                <MagicLetter>f</MagicLetter>
                <MagicLetter>u</MagicLetter>
                <MagicLetter>n</MagicLetter>
                <div style={{ clear: 'both' }} />
              </ParagraphEnormous>
            </Column>
          </Row>
          <Row>
            <Column md={10} lg={10} sm={12}>
              <ClaimWrapper>
                <Paragraph id="elephantTrigger">
                  <Title>{data[0]}</Title>
                  <br />
                  {data[1]}
                </Paragraph>
              </ClaimWrapper>
            </Column>
          </Row>
          <Row>
            <Column md={10} lg={10} sm={12}>
              <ClaimWrapper>
                <Paragraph>
                  <Title>{data[2]}</Title>
                  <br />
                  {data[3]}
                </Paragraph>
              </ClaimWrapper>
            </Column>
          </Row>
          <Row>
            <Column md={10} lg={10} sm={12}>
              <TeamTitleWrapper>
                <Paragraph>
                  <SectionTitle>{data[4]}</SectionTitle>
                </Paragraph>
              </TeamTitleWrapper>
            </Column>
          </Row>
          <Row>
            <Column md={10} lg={10} sm={12}>
              <TeamPhotoWrapper>
                <Paragraph>{data[5]}</Paragraph>
              </TeamPhotoWrapper>
              <SmallParagraph>{data[6]}</SmallParagraph>
            </Column>
          </Row>
          <Row>
            <Column md={4} lg={4} sm={6}>
              <MemberBlock>
                <div>
                  {children[0]}
                  <TeamMember>Nenad Cizl</TeamMember>
                  <p>co-founder & art director</p>
                </div>
                <div>
                  <MemberLink href="https://www.linkedin.com/in/nenadcizl" target="_blank">
                    <Linkedin />
                  </MemberLink>
                  <MemberLink href="https://www.instagram.com/cizl" target="_blank">
                    <Instagram />
                  </MemberLink>
                </div>
              </MemberBlock>
            </Column>
            <Column md={4} lg={4} sm={6}>
              <MemberBlock>
                <div>
                  {children[1]}
                  <TeamMember>Andrija Šulić</TeamMember>
                  <p>co-founder & product lead</p>
                </div>
                <div>
                  <MemberLink href="https://www.linkedin.com/in/andrijasulic" target="_blank">
                    <Linkedin />
                  </MemberLink>
                  <MemberLink href="https://www.instagram.com/andrijasulic" target="_blank">
                    <Instagram />
                  </MemberLink>
                </div>
              </MemberBlock>
            </Column>
            <Column md={4} lg={4} sm={6}>
              <MemberBlock>
                <div>
                  {children[2]}
                  <TeamMember>Tine Lugarič</TeamMember>
                  <p>co-founder & creative director</p>
                </div>
                <div>
                  <MemberLink href="https://www.linkedin.com/in/tinelugaric" target="_blank">
                    <Linkedin />
                  </MemberLink>
                  <MemberLink href="https://www.instagram.com/tinelugaric" target="_blank">
                    <Instagram />
                  </MemberLink>
                </div>
              </MemberBlock>
            </Column>
            <Column md={4} lg={4} sm={6}>
              <MemberBlock>
                <div>
                  {children[3]}
                  <TeamMember>Tamara Lugarič</TeamMember>
                  <p>account director</p>
                </div>
                <div>
                  <MemberLink href="https://www.linkedin.com/in/tamara-lugaric/" target="_blank">
                    <Linkedin />
                  </MemberLink>
                  <MemberLink href="https://www.instagram.com/tamc_ej/" target="_blank">
                    <Instagram />
                  </MemberLink>
                </div>
              </MemberBlock>
            </Column>
            <Column md={4} lg={4} sm={6}>
              <MemberBlock>
                <div>
                  {children[4]}
                  <TeamMember>Rok Radobuljac</TeamMember>
                  <p>head of digital product development</p>
                </div>
                <div>
                  <MemberLink href="https://www.linkedin.com/in/radobuljacrok" target="_blank">
                    <Linkedin />
                  </MemberLink>
                  <MemberLink href="https://www.instagram.com/radobuljacrok/" target="_blank">
                    <Instagram />
                  </MemberLink>
                </div>
              </MemberBlock>
            </Column>
            <Column md={4} lg={4} sm={6}>
              <MemberBlock>
                <div>
                  {children[6]}
                  <TeamMember>Dragan Petoš</TeamMember>
                  <p>development lead</p>
                </div>
                <div>
                  <MemberLink href="https://www.linkedin.com/in/draganpetos" target="_blank">
                    <Linkedin />
                  </MemberLink>
                </div>
              </MemberBlock>
            </Column>
          </Row>
        </Wrapper>
      </Container>
    </TeamRoot>
  );
};
