import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { responsiveFont } from 'styles/utils';

import { variables, breakpoints } from 'styles/variables';
import Facebook from 'assets/svg/facebook.svg';
import Instagram from 'assets/svg/instagram.svg';
import Linkedin from 'assets/svg/linkedin.svg';

// import { Intro } from 'components/intro/Intro';
import { Work } from 'components/work/Work';
import { WorkBlock } from 'components/work/WorkBlock';
import { WorkBlockImage } from 'components/work/WorkBlockImage';
import { WorkBlockTextLeft } from 'components/work/WorkBlockTextLeft';
import { WorkBlockTextRight } from 'components/work/WorkBlockTextRight';
import { Services } from 'components/services/Services';
import { ServicesBlock } from 'components/services/ServicesBlock';
import { Team } from 'components/team/Team';
import { Intro } from 'components/intro/Intro';
import { Clients } from 'components/clients/Clients';
import { ClientsBlock } from 'components/clients/ClientsBlock';
import { Footer } from 'components/footer/Footer';
import { Header } from 'components/header/Header';
import { HeaderLink } from 'components/header/HeaderLink';

import Img from 'gatsby-image';

import { graphql } from 'gatsby';

import styled from 'styled-components';

import WorkVideoBBMegaClip from '../assets/video/BigBang_Mega_clip.mp4';
import WorkVideoBBMega from '../assets/video/BigBang_Mega.mp4';
import WorkVideoBBMegaPath from '../assets/images/work_BigBang_Mega.jpeg';

import WorkVideoBBUAUClip from '../assets/video/BigBang_UAU_clip.mp4';
import WorkVideoBBUAU from '../assets/video/BigBang_UAU.mp4';
import WorkVideoBBUAUPath from '../assets/images/work_BigBang_UAU.jpeg';

import WorkVideoGoogleArtsAndCultureClip from '../assets/video/Google_Arts_And_Culture_clip.mp4';
import WorkVideoGoogleArtsAndCulture from '../assets/video/Google_Arts_And_Culture.mp4';
import WorkVideoGoogleArtsAndCulturePath from '../assets/images/work_Google_Arts_And_Culture.jpeg';

import WorkVideoFabulatorijClip from '../assets/video/fabulatorij.mp4';
import WorkVideoFabulatorijPath from '../assets/images/work_Fabulatorij.jpg';

import WorkVideoAR9Clip from '../assets/video/AR9.mp4';
import WorkVideoAR9Path from '../assets/images/work_AR9.jpg';

import PlayIcon from '../assets/svg/play-small-2.svg';

import ArrowVideoMP4 from '../assets/video/arrow.mp4';
import ArrowVideoWEBM from '../assets/video/arrow.webm';
import ArrowVideoPath from '../assets/images/arrow.jpg';

import reelVideo from '../assets/video/showreel_2023.mp4';

import VideoPlayer from 'components/video/VideoPlayer';

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import { BigButton } from 'components/button/BigButton';
import { Strong } from 'gsap';

const Title = styled.span`
  font-weight: 500;
`;

const StrongText = styled.p`
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 15px;
  display: none;
`;

const AwardTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 500;
`;

const AwardText = styled.p`
  margin-bottom: 5px;
  font-weight: 400;
  opacity: 0.5;
`;

const PlayButton = styled.button`
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  display: flex;
  background: #000000;

  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  padding: 5px 7px 5px 10px;
  border-radius: 5px;

  text-decoration: none;

  &:first-of-type {
    margin-left: 0;
  }

  transition: 300ms opacity ease-in-out;

  &:hover {
    opacity: 0.5;
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 18px;
    padding: 10px 15px 10px 20px;
    border-radius: 5px;
  }
`;

const PlayIconWrapper = styled.div`
  svg {
    position: relative;

    path {
      fill: #ffffff;
    }
    margin-left: 4px;
    margin-right: 0px;
    top: 0px;
    @media (min-width: ${breakpoints.md}) {
      top: 3px;
    }
  }
`;
export default ({ data }) => {
  useEffect(() => {
    // console.log('initialrender');
    // Video listeners and observers
    // const videoArrow = document.getElementById('videoArrow');
    /* const videoBevog1 = document.getElementById('videoBevog1');
    const videoBright = document.getElementById('videoBright');
    const videoStereosun = document.getElementById('videoStereosun');

    videoBevog1.addEventListener(
      'loadedmetadata',
      function () {
        this.currentTime = 0;
        this.pause();
      },
      false,
    );

    videoBright.addEventListener(
      'loadedmetadata',
      function () {
        this.currentTime = 0;
        this.pause();
      },
      false,
    );

    videoStereosun.addEventListener(
      'loadedmetadata',
      function () {
        this.currentTime = 0;
        this.pause();
      },
      false,
    );

    var isLeaving = false;
    const observer = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          isLeaving = true;
          entry.target.play();
        } else if (isLeaving) {
          entry.target.pause();
        }
      });
    });
    // observer.observe(videoArrow);
    observer.observe(videoBevog1);
    observer.observe(videoBright);
    observer.observe(videoStereosun);*/
  });

  const { t } = useTranslation();
  const team = [
    t('teamJumpTitle'),
    t('teamJumpDesc'),
    t('teamFunTitle'),
    t('teamFunDesc'),
    t('teamBand'),
    t('teamBandDesc'),
    t('teamLeads'),
  ];
  const intro = [t('intro1'), t('intro2'), t('intro3'), t('intro4'), t('intro5')];
  const footer = [t('noCompetition'), t('noCompetitionDesc'), t('meet')];
  const playerRef = React.useRef<HTMLVideoElement>(null);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [controls, setControls] = React.useState(true);

  const options = {
    preload: 'auto',
    controls: true,
    fullscreen: {
      options: {
        navigationUI: 'hide',
      },
    },
    userActions: {
      doubleClick: false,
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('useractive', () => {
      setControls(true);
    });

    player.on('userinactive', () => {
      setControls(false);
    });

    player.on('ended', () => {
      setFullscreen(false);
      playerRef?.current?.currentTime(0);
    });

    player.on('fullscreenchange', () => {
      if (!player.isFullscreen()) {
        handleStop();
      }
    });
  };

  const handlePlay = (source) => {
    // @ts-ignore
    playerRef?.current?.src({ src: source, type: 'video/mp4' });
    setFullscreen(true);
    playerRef?.current?.play();
  };

  const handleStop = () => {
    setFullscreen(false);
    playerRef?.current?.pause();
  };

  return (
    <>
      <Header>
        <HeaderLink name={t('work')} href="/work" />
        <HeaderLink name={t('meet')} href="/contact" />
      </Header>
      <Helmet title={t('pageTitle')} />
      {/* <ShowReel />*/}
      {/* fullscreen Video */}
      <div
        style={{ height: '100vh' }}
        className={
          !fullscreen
            ? 'opacity-0 invisible transition-opacity fixed'
            : 'opacity-100 visible z-10 w-full top-0 left-0 transition-opacity fixed'
        }
      >
        <VideoPlayer options={options} onReady={handlePlayerReady} />
        <button
          className={`${
            !controls ? 'opacity-0 delay-500' : 'opacity-100'
          } transition-opacity duration-700  absolute right-2 top-2 w-10 h-10 border text-white`}
          onClick={handleStop}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.5893 8.45868L15.932 14.1147L10.276 8.45868L8.39062 10.344L14.0466 16L8.39062 21.656L10.276 23.5413L15.932 17.8853L21.5893 23.5413L23.4746 21.656L17.8186 16L23.4746 10.344L21.5893 8.45868Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {/* / fullscreen Video */}
      <Intro data={intro}>
        <PlayButton onClick={() => handlePlay(reelVideo)}>
          {intro[4]}
          <PlayIconWrapper>
            <PlayIcon />
          </PlayIconWrapper>
        </PlayButton>
        <video
          id="videoArrow"
          autoPlay
          muted
          playsInline
          loop
          style={{
            width: '100%',
            maxWidth: '450px',
            outline: 'none',
            background: '#ffffff',
            border: 'none',
          }}
          poster={ArrowVideoPath}
        >
          <source src={ArrowVideoMP4} type="video/mp4" />
          <source src={ArrowVideoWEBM} type="video/webm" />
        </video>
      </Intro>

      <Team data={team}>
        <Img loading="eager" fluid={data.teamImage1.childImageSharp.fluid} alt="Nenad Cizl" />
        <Img loading="eager" fluid={data.teamImage2.childImageSharp.fluid} alt="Andrija Šulić" />
        <Img loading="eager" fluid={data.teamImage3.childImageSharp.fluid} alt="Tine Lugarič" />
        <Img loading="eager" fluid={data.teamImage4.childImageSharp.fluid} alt="Tamara Korat" />
        <Img loading="eager" fluid={data.teamImage5.childImageSharp.fluid} alt="Rok Radobuljac" />
        <Img loading="eager" fluid={data.teamImage6.childImageSharp.fluid} alt="Klara Koražija" />
        <Img loading="eager" fluid={data.teamImage7.childImageSharp.fluid} alt="Dragan Petoš" />
        <Img loading="eager" fluid={data.teamImage8.childImageSharp.fluid} alt="Jana Kocbek" />

      </Team>
      <Services servicesTitle={t('servicesTitle')}>
        <ServicesBlock animationDelay={0}>
          <Title>{t('researchTitle')}</Title>
          <ul>
            <li>{t('research1')}</li>
            <li>{t('research2')}</li>
            <li>{t('research3')}</li>
            <li>{t('research4')}</li>
          </ul>
        </ServicesBlock>
        <ServicesBlock animationDelay={100}>
          <Title>{t('designTitle')}</Title>
          <ul>
            <li>{t('design1')}</li>
            <li>{t('design2')}</li>
            <li>{t('design3')}</li>
            <li>{t('design4')}</li>
          </ul>
        </ServicesBlock>
        <ServicesBlock animationDelay={200}>
          <Title>{t('developmentTitle')}</Title>
          <ul>
            <li>{t('development1')}</li>
            <li>{t('development2')}</li>
            <li>{t('development3')}</li>
            <li>{t('development4')}</li>
          </ul>
        </ServicesBlock>
        <ServicesBlock animationDelay={300}>
          <Title>{t('communicationTitle')}</Title>
          <ul>
            <li>{t('communication1')}</li>
            <li>{t('communication2')}</li>
            <li>{t('communication3')}</li>
            <li>{t('communication4')}</li>
          </ul>
        </ServicesBlock>
      </Services>

      <Work workTitle={t('workTitle')} allWorkTitle={t('allWorkTitle')}>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Big Bang"
            year="2021-2022"
            services="3D animated TVC"
            //extras="Production with Rok Frešer"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBBUAUClip}
              onClick={() => handlePlay(WorkVideoBBUAU)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="Bevog" year="2021" services="Lola Packaging design" extras="" />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBevogLola.childImageSharp.fluid}
              alt="Bevog Lola Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Google Arts & Culture"
            year="2021"
            services="Online film"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>The Golden City Gate</AwardTitle>,
              <AwardText>Platinum</AwardText>,
            ]}
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoGoogleArtsAndCultureClip}
              onClick={() => handlePlay(WorkVideoGoogleArtsAndCulture)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="ArtRebel9"
            year="2022"
            services="UX/UI Design, Development"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Awwwards</AwardTitle>,
              <AwardText>Honorable Mention</AwardText>,
            ]}
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoAR9Clip}
              onClick={() => window.open('https://www.artrebel9.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
        <WorkBlockTextLeft
            title="Big Bang"
            year="2021"
            services="Immersive 3D billboard"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>SOF</AwardTitle>,
              <AwardText>Gold</AwardText>,
            ]}
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBBMegaClip}
              onClick={() => handlePlay(WorkVideoBBMega)}
            />
          </WorkBlockImage>
        </WorkBlock>

        <WorkBlock>
          <WorkBlockTextLeft
            title="Borštnikovo"
            year="2013"
            services="Festival Branding"
            extras=""
          />
          <WorkBlockImage lg={5} md={5}>
            <Img fluid={data.workImageBorstnikovo.childImageSharp.fluid} alt="Borštnikovo Poster" />
          </WorkBlockImage>
          <WorkBlockTextLeft
            title="Hodnik"
            year="2015 - 2019"
            services="Concept, Branding, Events"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Brumen</AwardTitle>,
              <AwardText>Recognition for excellent Slovenian design</AwardText>,
            ]}
            mobileOnly="yes"
          />
          <WorkBlockImage lg={3} md={3}>
            <Img
              loading="eager"
              fluid={data.workImageHodnik.childImageSharp.fluid}
              alt="Hodnik Poster"
              style={{ width: '100%' }}
            />
          </WorkBlockImage>
          <WorkBlockTextRight
            title="Hodnik"
            year="2015 - 2019"
            services="Concept, Branding, Events"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Brumen</AwardTitle>,
              <AwardText>Recognition for excellent Slovenian design</AwardText>,
            ]}
          />
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Fabulatorij"
            year="2021"
            services="Branding, UX/UI Design, Development"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>CSSDesignAwards</AwardTitle>,
              <AwardText>Site of the day</AwardText>,
              <AwardTitle>Awwwards</AwardTitle>,
              <AwardText>Honorable Mention</AwardText>,
            ]}
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoFabulatorijClip}
              onClick={() => window.open('https://www.fabulatorij.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Parson Premium Gin"
            year="2021"
            services="Branding and packaging design"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Graphis</AwardTitle>,
              <AwardText>Gold</AwardText>,
              <AwardTitle>World Gin Awards</AwardTitle>,
              <AwardText>Bronze</AwardText>,
            ]}
          />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageParsonsGin.childImageSharp.fluid}
              alt="Parson Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Malner cider"
            year="2021"
            services="Packaging design"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Graphis</AwardTitle>,
              <AwardText>Silver</AwardText>,
            ]}
          />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageMalner.childImageSharp.fluid}
              alt="Malner Cider Branding"
            />
          </WorkBlockImage>
        </WorkBlock>

        <br />
        <br />
        <center>
          <BigButton name={t('allWorkTitle')} href="/work" />
        </center>
      </Work>

      <Clients clientsTitle={t('brandsTitle')} animationDelay={0}>
        <ClientsBlock animationDelay={0}>
          <ul>
            <li>Renault</li>
            <li>Microsoft</li>
            <li>BMW</li>
            <li>Mercedes Benz</li>
            <li>UniCredit Bank</li>
            <li>Nova KBM Bank</li>
            <li>Heineken</li>
            <li>Google</li>
          </ul>
        </ClientsBlock>
        <ClientsBlock animationDelay={100}>
          <ul>
            <li>NLB</li>
            <li>Joan</li>
            <li>Generali</li>
            <li>Lidl</li>
            <li>Big Bang</li>
            <li>Samsung</li>
            <li>Medex</li>
            <li>2Cellos</li>
          </ul>
        </ClientsBlock>
        <ClientsBlock animationDelay={200}>
          <ul>
            <li>Narayan</li>
            <li>Povio</li>
            <li>Bevog Craft Brewery</li>
            <li>Nissan</li>
            <li>Volkswagen</li>
            <li>Inotherm</li>
            <li>Celtra</li>
            <li>s.Oliver</li>
          </ul>
        </ClientsBlock>
      </Clients>
      <Footer
        data={footer}
        social={[
          { icon: <Instagram />, to: 'https://www.instagram.com/trampolin.studio' },
          { icon: <Facebook />, to: 'https://www.facebook.com/Trampolin.studio' },
          { icon: <Linkedin />, to: 'https://www.linkedin.com/company/trampolin-studio' },
        ]}
      ></Footer>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "contact", "index", "terms", "work"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    workImageMalner: file(relativePath: { eq: "images/work_Malner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMalner2: file(relativePath: { eq: "images/work_Malner_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageHodnik: file(relativePath: { eq: "images/work_hodnik1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageParsonsGin: file(relativePath: { eq: "images/work_ParsonsGIn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 0) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBorstnikovo: file(relativePath: { eq: "images/work_borstnikovo.png" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLola: file(relativePath: { eq: "images/work_Bevog_Lola.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLola2: file(relativePath: { eq: "images/work_Bevog_Lola_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    teamImage1: file(relativePath: { eq: "images/team_NC_5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    teamImage2: file(relativePath: { eq: "images/team_AS_4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
        teamImage6: file(relativePath: { eq: "images/team_KK_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    teamImage3: file(relativePath: { eq: "images/team_TL_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    teamImage4: file(relativePath: { eq: "images/team_TK_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    teamImage5: file(relativePath: { eq: "images/team_RR_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    teamImage7: file(relativePath: { eq: "images/team_DP_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    teamImage8: file(relativePath: { eq: "images/team_JK_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 420, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`;
