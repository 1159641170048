import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables} from 'styles/variables';
import { Column } from 'components/grid/Column';

interface ClientsBlockProps {
  children: ReactNode;
  animationDelay: number;
}

const ParagraphSmall = styled.div`
  ${responsiveFont(22, 28)};

  font-family: ${variables.font.familyHeading};
  font-weight: 300;
`;

export const ClientsBlock = ({ children, animationDelay }: ClientsBlockProps) => (
  <Column md={4} lg={4} sm={12}>
    <div
      data-sal="fade"
      data-sal-delay={animationDelay}
      data-sal-duration="700"
      data-sal-easing="ease"
    >
      <ParagraphSmall>{children}</ParagraphSmall>
    </div>
  </Column>
);
