import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Row } from 'components/grid/Row';

interface ClientsProps {
  children: ReactNode;
  clientsTitle: string;
  animationDelay: number;
}

const ClientsRoot = styled.div`
  background: ${variables.colors.background};
`;

const Wrapper = styled.div`
  padding: 60px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 120px 0;
  }
`;

const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 500;
  padding-bottom: 50px;
`;

export const Clients = ({ children, clientsTitle, animationDelay }: ClientsProps) => (
  <ClientsRoot>
    <Container>
      <Wrapper>
        <div
          data-sal="slide-up"
          data-sal-delay={animationDelay}
          data-sal-duration="700"
          data-sal-easing="ease"
        >
          <SectionTitle>{clientsTitle}</SectionTitle>
        </div>
        <Row>{children}</Row>
      </Wrapper>
    </Container>
  </ClientsRoot>
);
