import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { motion } from 'framer-motion';

interface MagicLetterProps {
  children: ReactNode;
}
const Letter = styled.div`
  float: left;
  cursor: pointer;
`;

export const MagicLetter = ({ children }: MagicLetterProps) => (
  <Letter>
    <div data-sal="slide-up" data-sal-delay="0" data-sal-duration="700" data-sal-easing="ease">
      <motion.div whileHover={{ y: -20 }}>{children}</motion.div>
    </div>
  </Letter>
);
