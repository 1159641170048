import React from 'react';
import styled from 'styled-components';

import StarterLogo from 'assets/svg/trampolin-logo.svg';

import { Container } from 'components/container/Container';
import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';

import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Trampolin from 'assets/svg/trampolin.svg';

import { Link, useI18next } from 'gatsby-plugin-react-i18next';

interface Social {
  icon: React.ReactNode;
  to: string;
}

const FooterRoot = styled.div`
  background-color: #e7917a;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 0px;
  padding: 40px 0;
`;

const List = styled.ul`
  display: flex;
`;

const ListItem = styled.li`
  margin-right: 30px;

  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  padding: 60px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 120px 0;
  }
`;

const Paragraph = styled.p`
  ${responsiveFont(24, 38)};

  font-family: ${variables.font.familyHeading};
  font-weight: 300;
`;

const Title = styled.p`
  ${responsiveFont(46, 76)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  line-height: 46px;
  padding-bottom: 30px;
  @media (min-width: ${breakpoints.md}) {
    line-height: 76px;
  }
`;

const Logo = styled(StarterLogo)`
  display: block;

  height: 28px;
  width: auto;

  @media (min-width: ${breakpoints.md}) {
    height: 28px;
  }
`;

const LargeLogo = styled(Trampolin)`
  display: block;

  max-width: 250px;
  padding-bottom: 30px;
  @media (min-width: ${breakpoints.md}) {
    max-width: 500px;
  }
`;

const MainButton = styled(AniLink)`
  ${responsiveFont(24, 38)};
  font-family: ${variables.font.familyHeading};
  font-weight: 500;
  color: ${variables.colors.font};
  padding-top: 20px;
  transition: 200ms opacity ease-in-out;
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
`;

export const Footer = ({ data, social }) => {
  const { languages, language, originalPath } = useI18next();

  return (
    <FooterRoot>
      <Container>
        <Wrapper>
          <Row>
            <Column md={8} lg={8} sm={12}>
              <LargeLogo fill="#000" />
              <Title>{data[0]}</Title>
              <Paragraph>
                {data[1]}
                <br />
                <br />
              </Paragraph>
              <div
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-duration="700"
                data-sal-easing="ease"
              >
                <MainButton paintDrip hex="#000" to="/contact">
                  {data[2]}
                </MainButton>
              </div>
            </Column>
          </Row>
        </Wrapper>
        <Content>
          <List>
            {social.map((item) => (
              <ListItem key={item.to}>
                <a href={item.to} target="_blank" rel="noopener noreferrer">
                  {item.icon}
                </a>
              </ListItem>
            ))}
          </List>
          <p className="absolute top-[70%] left-0 w-full md:static md:top-auto">
            © {new Date().getFullYear()} Trampolin&nbsp;&bull;&nbsp;
            {languages.map((lng) => (
              <span key={lng} className={language !== lng ? 'inline' : 'hidden'}>
                <Link to={originalPath} language={lng}>
                  {lng === 'en' ? 'English' : 'Slovenian'}
                </Link>
              </span>
            ))}
          </p>
          <AniLink paintDrip hex="#000" to="/">
            <Logo />
          </AniLink>
        </Content>
      </Container>
    </FooterRoot>
  );
};
