import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Column } from 'components/grid/Column';

interface ServicesBlockProps {
  children: ReactNode;
  animationDelay: number;
}

const Paragraph = styled.div`
  ${responsiveFont(24, 36)};

  font-family: ${variables.font.familyHeading};
  font-weight: 300;
  padding-bottom: 30px;
`;

export const ServicesBlock = ({ children, animationDelay }: ServicesBlockProps) => (
  <Column md={6} lg={6} sm={12}>
    <div
      data-sal="slide-up"
      data-sal-delay={animationDelay}
      data-sal-duration="700"
      data-sal-easing="ease"
    >
      <Paragraph>{children}</Paragraph>
    </div>
  </Column>
);
