import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Row } from 'components/grid/Row';

interface ServicesProps {
  children: ReactNode;
  servicesTitle: string;
}
const ServicesRoot = styled.div`
  background: ${variables.colors.background};
`;

const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 500;
  padding-bottom: 50px;
`;

const Wrapper = styled.div`
  padding: 60px 0;

  border-bottom: 1px solid #e5e5e5;

  @media (min-width: ${breakpoints.md}) {
    padding: 120px 0;
  }
`;


export const Services = ({ children, servicesTitle }: ServicesProps) => (
  <ServicesRoot>
    <Container>
      <Wrapper>
        <div data-sal="slide-up" data-sal-delay="0" data-sal-duration="700" data-sal-easing="ease">
          <SectionTitle>{servicesTitle}</SectionTitle>
        </div>
        <Row>{children}</Row>
      </Wrapper>
    </Container>
  </ServicesRoot>
);
