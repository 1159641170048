import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';
import { Highlight } from 'components/intro/Highlight';

const IntroWrapper = styled.div`
  min-height: 100vh;
  background: ${variables.colors.background};
`;

const Wrapper = styled.div`
  padding: 0px 0;
  @media (min-width: ${breakpoints.md}) {
    padding: 120px 0;
  }
`;

const Paragraph = styled.div`
  ${responsiveFont(24, 38)};
  font-family: ${variables.font.familyHeading};
  color: black;
  font-weight: 300;
  padding-top: 10px;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 100px;
  }
`;

const SubText = styled.p`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  color: black;
  font-weight: 400;
  padding-top: 20px;
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

const VideoWrapper = styled.div`
  background: #ffffff;
  outline: none;
  overflow: hidden;
  border: none;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 70px;
  }
`;

export const Intro = ({ data, children }) => (
  <IntroWrapper>
    <Container>
      <Wrapper>
        <Row>
          <Column md={12} lg={8} sm={12}>
            <Paragraph>
              <div
                data-sal="slide-down"
                data-sal-delay="0"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                {data[0]}
                <br />
                <Highlight>{data[1]}</Highlight>
                <br />
              </div>
              <div
                data-sal="fade"
                data-sal-delay="300"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                {data[2]}
                <br />
                <SubText>{data[3]}</SubText>
                <br />
                {children[0]}
              </div>
            </Paragraph>
          </Column>
          <Column md={12} lg={4} sm={12}>
            <div
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <VideoWrapper>{children[1]}</VideoWrapper>
            </div>
          </Column>
        </Row>
      </Wrapper>
    </Container>
  </IntroWrapper>
);
